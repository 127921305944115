@import 'reset.css';

.blog-container, .blog-container *, .author-container, .author-container *, .stream-container, .stream-container * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, textarea, input, select {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

body {
    line-height: 1.3em;
    color: #000;
    font-size: 62.5%;
    background: #ffffff;
}

body.error {
    background: no-repeat center center scroll;
    background-image: url(/images/404.png);
}

body.fatal {
    background: no-repeat center center scroll;
    background-image: url(/images/500.png);
}

.flexslider {
    margin-bottom: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    position: relative;
}

a {
    color: #000;
    text-decoration: underline;
}

a:hover {
    color: #000;
    text-decoration: underline;
}

.clr {
    clear: both;
    font-size: 1px;
    line-height: 1px;
    height: 0;
    overflow: hidden;
}

.clear:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clear {
    display: inline-block;
}

/* Hide from IE Mac \*/
.clear {
    display: block;
}

/* End hide from IE Mac */

::selection {
    background: #000;
    color: #ffffff;
}

::-moz-selection {
    background: #000;
    color: #ffffff;
}

.image_left {
    float: left;
    margin: 0 40px 20px 0;
}

.image_right {
    float: right;
    margin-left: 0 0 20px 40px;
}

.button, a.button {
    font-size: 11px;
    line-height: 20px;
    color: #ffffff;
    padding: 10px 15px;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    margin: 0;
    border: none;
    background: #000;
    cursor: pointer;
    text-decoration: none;
    text-decoration: none !important;
}

.b-info-msg {
    width: 220px;
    display: none;
    position: fixed;
    text-align: center;
    right: 0;
    top: 100px;
    z-index: 100;
    padding: 50px 30px 30px 30px;
    background: #fff;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-info-name {
    font-size: 1.6em;
    line-height: 1.3em;
    color: #000;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 20px;
}

.b-info-text {
    font-size: 1.4em;
    font-weight: normal;
    color: #000;
    line-height: 1.3em;
    margin-bottom: 20px;
}

.b-info-msg .hide-msg {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 23;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #848696;
    text-decoration: none;
    padding-left: 16px;
    background: url(/images/close_sm.png) no-repeat center left;
}

/* grid */
/*  SECTIONS  */
.section {
    clear: both;
    padding: 0px;
    margin: 0px;
}

/*  COLUMN SETUP  */
.col {
    display: block;
    float: left;
    margin: 0 0 0 4%;
}

.col:first-child {
    margin-left: 0;
}

/*  GROUPING  */
.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1; /* For IE 6/7 */
}

/*  GRID OF TWO  */
.span_2_of_2 {
    width: 100%;
}

.span_1_of_2 {
    width: 48%;
}

/*  GRID OF THREE  */
.span_3_of_3 {
    width: 100%;
}

.span_2_of_3 {
    width: 65.33%;
}

.span_1_of_3 {
    width: 30.66%;
}

/*  GRID OF FOUR  */
.span_4_of_4 {
    width: 100%;
}

.span_3_of_4 {
    width: 74%;
}

.span_2_of_4 {
    width: 48%;
}

.span_1_of_4 {
    width: 22%;
}

/*  GRID OF FIVE  */
.span_5_of_5 {
    width: 100%;
}

.span_4_of_5 {
    width: 79.2%;
}

.span_3_of_5 {
    width: 58.4%;
}

.span_2_of_5 {
    width: 37.6%;
}

.span_1_of_5 {
    width: 16.8%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 680px) {
    .col {
        margin: 0 0 1% 0%;
    }

    .span_1_of_5, .span_2_of_5, .span_3_of_5, .span_4_of_5, .span_5_of_5 {
        width: 100%;
    }

    .span_1_of_4, .span_2_of_4, .span_3_of_4, .span_4_of_4 {
        width: 100%;
    }

    .span_3_of_3, .span_2_of_3, .span_1_of_3 {
        width: 100%;
    }

    .span_2_of_2, .span_1_of_2 {
        width: 100%;
    }
}

/* banner */
.b-bblock-header {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.b-bblock-header img {
    width: 100%;
}


.b-bblock-close {
    /*background-color: #f40028;*/
    background-color: #edeef4;
    color: #848696;
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px 9px 6px 15px;
    font-size: 16px;
    /*opacity: 0.4;*/
    font-weight: bold;
    display: flex;
    vertical-align: center;
    cursor: pointer;
}

.b-bblock-close-ico {
    width: 20px;
    height: 20px;
    background: url(/images/close.png) no-repeat 0 0;
    margin-left: 7px;
}

.b-bblock-close div:first-child {
    line-height: 24px;
}

/* page */
.b-layout, .b-block-wrap {
    max-width: 1120px;
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
}

.b-content-holder {
    position: relative;
}

.b-content.mlogo {
    background: url(/images/mlogo.png) no-repeat bottom right;
    min-height: 700px;
}

.b-content.error404, body.fatal .b-content {
    min-height: 700px;
}

.b-error-holder {
    width: 300px;
    text-align: center;
    margin: 200px auto 0 auto;
    padding: 30px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-content.sidebar {
    width: 100%;
    margin-right: -298px;
    float: left;
}

.b-content.sidebar .b-content-block {
    margin-right: 298px;
}

.b-sidebar-links li {
    margin-bottom: 15px;
    position: relative;
    box-sizing: border-box;
}

.b-sidebar-links li img {
    max-width: 100%;
    /*cursor: zoom-in;*/
}

.b-sidebar-links li a.link {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-family: 'Roboto Slab', serif;
    font-size: 1.4em;
    color: #fff;
    text-decoration: none;
    line-height: 1.7em;
    font-weight: bold;
}

.b-sidebar-links li a.link.dark {
    color: #848696;
}

.b-sidebar-block {
    float: right;
    width: 272px;
    margin-bottom: 30px;
}

.b-sidebar-block.main {
    padding-top: 30px;
}

.b-mediananny-block, .b-cr-block {
    margin: 31px 0 30px 0;
    position: relative;
}

.b-mediananny-block img, .b-cr-block img {
    max-width: 100%;
}

.b-mediananny-block-info, .b-cr-block-info {
    position: absolute;
    top: 30px;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.b-mediananny-block-top, .b-cr-block-top {
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #fff;
    line-height: 1.5em;
    margin-bottom: 90px;
    padding: 0 15px;
}

.b-mediananny-block-text, .b-cr-block-text {
    font-size: 1.4em;
    color: #fff;
    line-height: 1.35em;
    margin-bottom: 40px;
    padding: 0 15px;
}

.b-block-name {
    font-size: 1.2em;
    line-height: 1.3em;
    color: #fff;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    text-transform: uppercase;
    position: relative;
}

.b-block-name h1 {
    display: inline;
}

h1.title {
    font-size: 1.6em;
    line-height: 1.3em;
    color: #000;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 20px;
}

h1.name, h2.name {
    font-size: 1.6em;
    line-height: 1.3em;
    color: #000;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 20px;
}

.b-block-name span {
    display: inline-block;
    background: #000;
    text-align: center;
    min-width: 120px;
    padding: 8px;
}

.b-block-name a {
    text-decoration: none;
    color: #fff;
}

.b-block-name a.sub {
    padding-right: 20px;
    background: url(/images/arrow_down.png) no-repeat center right;
}

.b-all {
    text-align: right;
}

.b-all.margin {
    margin-top: 20px;
}

.b-all a {
    font-size: 1.6em;
    line-height: 1.3em;
    display: inline-block;
    text-decoration: underline;
    color: #000;
    font-weight: 500;
}

.b-share {
    margin-bottom: 30px;
}

.b-breadcrumbs {
    font-size: 1.2em;
    color: #717171;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
}

.b-breadcrumbs li {
    display: inline-block;
    margin-right: 7px;
}

.b-breadcrumbs a {
    color: #000;
    display: inline-block;
    text-decoration: none;
}

.b-breadcrumbs a.last {
    padding-right: 0;
    background: none;
}

.b-feed-calendar {
    position: absolute;
    right: 0;
    top: 7px;
    z-index: 1;
}

.b-feed-calendar a.show-calendar {
    font-size: 1.6em;
    line-height: 1.3em;
    font-weight: 500;
    display: inline-block;
}

.b-feed-calendar a.hide-calendar {
    position: absolute;
    top: 16px;
    right: 30px;
    z-index: 23;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #848696;
    text-decoration: none;
    padding-left: 16px;
    background: url(/images/close_sm.png) no-repeat center left;
}

.b-feed-calendar .b-block.calendar {
    display: none;
    min-width: 350px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 22;
    padding: 40px 30px 30px 30px
}

.b-block.calendar {
    border-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
    background: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
}

.b-calendar {
    position: relative;
}

.b-calendar-onmap {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    width: 320px;
}

.b-block.calendar table {
    width: 100%;
}

.b-block.calendar table th {
    display: none;
}

.b-block.calendar table td {
    text-align: center;
    color: #000000;
    font-size: 1.5em;
    line-height: 1.3em;
    font-weight: 500;
}

.b-block.calendar table td a {
    padding: 7px 0;
    width: 32px;
    margin-bottom: 5px;
    display: inline-block;
    text-decoration: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-block.calendar table td a.ui-state-active, .b-block.calendar table td a:hover {
    background: #edeef4;
    color: #000000;
}

.b-block.calendar .ui-datepicker-title {
    text-align: center;
    color: #000000;
    font-size: 1.6em;
    position: relative;
    margin-bottom: 10px;
    line-height: 1.3em;
    font-weight: bold;
}

.b-block.calendar .ui-datepicker .ui-datepicker-prev, .b-block.calendar .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 4px;
    width: 14px;
    height: 11px;
    display: inline-block;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 33;
}

.b-block.calendar .ui-datepicker .ui-datepicker-prev {
    left: 15px;
    background: url(/images/calnav.png) no-repeat 0 0;
}

.b-block.calendar .ui-datepicker .ui-datepicker-next {
    right: 15px;
    background: url(/images/calnav.png) no-repeat 100% 0;
}

.b-tabs {
    margin-bottom: 30px;
    overflow: hidden;
    zoom: 1;
}

.b-tabs li {
    display: inline-block;
    margin-right: 6px;
    font-size: 1.2em;
    line-height: 1.3em;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
}

.b-tabs li a {
    text-decoration: none;
    color: #000;
    display: inline-block;
    padding: 8px;
    border: 2px solid #000;
}

.b-tabs li a.active, .b-tabs li a:hover {
    background: #000;
    border: 2px solid #000;
    color: #fff;
}

.b-social {
    float: right;
    margin-left: 20px;
}

.b-social a {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-left: 4px;
}

.b-social a.facebook {
    background: url(/images/social.png) no-repeat 0 0;
}

.b-social a.telegram {
    background: url(/images/social.png) no-repeat -22px 0;
}

/* header */
.b-header {
    padding: 20px 20px 35px 20px;
    position: relative;
    max-width: 1120px;
    margin: 0 auto;
}

.b-top-line {
    background: #edeef4;
    overflow: hidden;
    zoom: 1;
}

.h-top-line {
    padding: 5px 20px;
    position: relative;
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    height: 35px;
    align-items: center;
}

.b-top-right {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 1;
    transform: translate(0px, -50%);
}

.b-logo {
    float: left;
}

.b-logo img {
    max-height: 95px;
    max-width: 100%;
    display: inline-block;
}

.b-header-logo {
    text-align: center;
    padding-top: 20px;
}

.b-header-logo.cr {
    padding-top: 10px;
}

.b-header-logo img {
    max-width: 100%;
}

#header.fixed {

}

.b-header-holder {
    position: relative;
}

.b-user-auth {
    float: left;
    font-size: 1.2em;
    color: #000;
    font-weight: bold;
    line-height: 1.3em;
}

.b-user-auth a {
    color: #000;
    text-decoration: none;
    display: inline-block;
}

.b-user-auth a.login {
    text-decoration: none;
}

.b-auth-header-ava {
    float: left;
    margin-right: 10px;
    display: none;
    vertical-align: 4px;
}

.b-auth-header-ava img {
    height: 22px;
}

.b-auth-header-info {
    display: table-cell;
    padding-top: 5px;
}

.b-search-header {
    position: absolute;
    bottom: 10px;
    right: 0;
    z-index: 2;
}

.b-search-header-form {
    display: none;
}

.b-search-header-form .submit {

}

.b-search-header-form .close {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    background: url(/images/close.png) no-repeat 0 0;
}

.b-search-header-form .input {
    background: #fff;
    font-weight: bold;
    border-bottom: 1px solid #d6d7e2;
    color: #000000;
    padding: 4px 30px 4px 0;
    font-size: 14px;
    line-height: 16px;
    width: 200px;
}

.b-search-header-ico {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
}

.b-search-header-ico a {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    background: url(/images/src_btn.png) no-repeat 0 0;
}

.b-lang {
    position: absolute;
    bottom: 12px;
    right: 45px;
    z-index: 1;
    font-size: 1.4em;
    font-weight: bold;
    color: #848696;
    line-height: 1.3em;
    text-transform: uppercase;
}

.b-lang a {
    color: #000;
    text-decoration: none;
    padding-left: 4px;
}

.b-lang a.active {
    color: #848696;
}

.b-lang a:hover {
    color: #848696;
}

/* menu */
.b-main-menu {
    clear: both;
    padding-top: 20px;
}

.h-main-menu {
    position: relative;
}

.b-site-nav {

}

.b-site-nav li {
    display: inline-block;
    margin-right: 40px;
}

.b-site-nav li ul {
    position: absolute;
    display: none !important;
    left: 0;
    top: 31px;
    z-index: 33;
    padding: 0 20px;
    background: #fff;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-site-nav li ul li {
    float: none;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    text-align: center !important;
}

.b-site-nav li ul li a {
    text-decoration: none;
    display: inline-block;
    padding: 10px 30px;
    border-top: 1px solid #d6d8e6 !important;
}

.b-site-nav li:first-child a {
    border-top: none !important;
}

.b-menu-ico, .b-site-nav-ico {
    display: none;
}

.b-site-nav-ico {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 333;
}

.b-menu-ico a {
    width: 24px;
    height: 24px;
    background: url(/images/menu_ico.png) no-repeat 0 0;
    display: block;
}

.b-site-nav-ico a {
    width: 24px;
    height: 24px;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    background: url(/images/close.png) no-repeat 0 0;
    display: block;
}

.b-site-nav a {
    color: #000;
    font-size: 1.4em;
    line-height: 1.3em;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
}

.b-site-nav a:hover, .b-site-nav a.active {
    color: #000;
    border-bottom: 2px solid #000;
    text-decoration: none;
}

.b-site-nav li li {
    margin-right: 0;
    text-align: center;
}

.b-site-nav li li a {
    color: #000;
    padding: 5px 10px;
    border-bottom: none;
    display: block;
    background: none;
}

.b-site-nav li li a:hover {
    color: #000;
    border-bottom: none;
    text-decoration: underline;
}

.b-sub-menu {
    position: absolute;
    display: none;
    left: 0;
    top: 31px;
    z-index: 33;
    padding: 0 20px;
    background: #fff;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-sub-menu li {
    float: none;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #000;
    line-height: 1.5em;
    text-align: center;
}

.b-sub-menu li a {
    text-decoration: none;
    padding: 10px 30px;
    border-top: 1px solid #d6d8e6;
}

.b-sub-menu li:first-child a {
    border-top: none;
}

/* slider */
.b-slider-holder {
    position: relative !important;
    margin: 0 auto 30px auto !important;
}

.b-slider-holder.flexslider .slides > li {
    position: relative;
}

.flexslider {
    border: none !important;
}

.b-slider-holder .flex-control-nav {
    display: none;
}

.b-slider-img:after {
    display: block;
    position: relative;
    background-image: linear-gradient(180deg, transparent 0, #000);
    margin-top: -170px;
    height: 170px;
    width: 100%;
    content: "";
}

.b-slider-text {
    position: absolute;
    bottom: 30px;
    left: 24px;
    margin-bottom: 0;
    padding-right: 24px;
    z-index: 2;
}

.b-slider-title {
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #fff;
    line-height: 1.5em;
    display: inline;
}

.b-slider-title a {
    color: #fff;
    text-decoration: none;
}

/* static text */
.b-static-text {

}

.b-static-text.error {

}

.b-static-text.fullwidth {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
}

.b-static-img {
    position: relative;
    margin-bottom: 20px;
}

.b-static-img-desc {
    font-size: 1.2em;
    color: #848696;
    line-height: 1.3em;
    margin-bottom: 20px;
    font-style: italic;
}

.b-static-text img, .b-static-img img, .b-static-img iframe, .b-static-text video, .b-static-text object, .b-static-text embed, .b-static-text iframe, .b-static-video embed, .b-static-video video, .b-static-video object, .b-static-video iframe {
    max-width: 100%;
}

.b-static-img img {
    height: auto;
    display: block;
    max-width: 100%;
}

.b-static-text img {
    height: auto;
}

.b-static-img.b-news-image img {
    width: 100%;
}

.b-static-text h1 {
    font-size: 3.4em;
    color: #35363e;
    line-height: 1.2em;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
}

.b-static-text h2 {
    font-size: 2.6em;
    color: #000000;
    line-height: 1.2em;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
}

.b-static-text h3 {
    font-size: 2em;
    color: #000000;
    line-height: 1.2em;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
}

.b-static-text h4 {
    font-size: 1.8em;
    color: #000000;
    line-height: 1.3em;
    margin-bottom: 20px;
    font-weight: bold;
}

.b-static-text p {
    font-size: 1.6em;
    color: #1c1c1b;
    line-height: 1.4em;
    padding-bottom: 20px;
}

.b-static-text p strong {
    font-weight: bold;
}

.b-static-text p em {
    font-style: italic;
}

.b-static-text blockquote {
    font-size: 2em;
    font-style: italic;
    line-height: 1em;
    margin-left: 20px;
    padding-left: 30px;
    background: url(/images/blockquote.png) no-repeat top left;
    font-weight: 300;
    margin-bottom: 20px;
    clear: both;
}

.b-static-text blockquote p {
    font-size: 18px;
    padding: 0;
}

.b-static-text ul, .b-static-text ol {
    margin: 0 0 20px 32px;
}

.b-static-text ul li {
    font-size: 1.6em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding-bottom: 8px;
    list-style: disc;
    list-style-position: inside;
    font-weight: 300;
}

.b-static-text ol li {
    font-size: 1.6em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding-bottom: 8px;
    list-style: decimal;
    font-weight: 300;
}

.b-static-text table {
    width: 100%;
    display: inline-table;
    overflow-x: auto;
    margin-bottom: 20px;
}

.b-static-text th, .b-static-text thead td {
    font-weight: bold;
    vertical-align: top;
    font-size: 1.4em;
    color: #fff;
    background: #1c1c1b;
    line-height: 1.3em;
    text-align: left;
    padding: 10px;
    border: 1px solid #cdcdcd;
}

.b-static-text td {
    vertical-align: top;
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    text-align: left;
    padding: 10px;
    border: 1px solid #cdcdcd;
}

.b-static-text tr:nth-child(odd) {
    background: #edeef4
}

.b-static-text td p {
    font-size: 14px;
    color: #1c1c1b;
    padding: 0;
}

.b-static-text th p {
    font-size: 14px;
    color: #fff;
    padding: 0;
}

/* gallery */
.b-media-gallery {
    position: relative;
    margin-bottom: 30px;
}

.b-media-gallery .flex-direction-nav a {
    width: 12px;
    height: 32px;
    text-indent: -9999px;
    font-size: 0 !important;
}

.b-media-gallery .flex-direction-nav .flex-prev {
    left: 20px !important;
    background: url(/images/nav.png) no-repeat 0 0;
}

.b-media-gallery .flex-direction-nav .flex-next {
    right: 20px !important;
    background: url(/images/nav.png) no-repeat 100% 0;
}

.b-media-gallery .flex-direction-nav a:before {
    font-size: 0 !important;
}

/* team */
.b-team-list {

}

.b-team-item {
    margin-bottom: 30px;
    position: relative;
}

.b-team-image {
    margin-bottom: 20px;
}

.b-team-image img, .b-team-about img {
    max-width: 100%;
    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-team-about {
    text-align: center;
}

.b-team-item h3 {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 1.8em;
    color: #1c1c1b;
    line-height: 1.3em;
    margin-bottom: 5px;
}

.b-team-item h3 a {
    color: #ffffff;
    text-decoration: none;
}

.b-team-position {
    font-size: 1.4em;
    color: #1f3353;
    line-height: 1.3em;
    margin-bottom: 10px;
}

/* news */
.b-news-list {

}

.b-news-list.last {
    margin-bottom: 30px;
}

.b-news-list.last .b-block-name {
    display: none;
}

.b-feed-date {
    font-weight: bold;
    font-size: 1.6em;
    color: #898989;
    line-height: 1.3em;
    margin-bottom: 15px;
}

.b-news-category {
    margin-bottom: 25px;
}

.b-top-holder {
    margin-bottom: 0;
}

.b-news-top.hero .b-news-item {
    margin-bottom: 30px;
}

.b-news-list.list-special .b-news-item {
    margin-bottom: 30px;
}

.b-top-sidebar .b-news-item {
    margin-bottom: 30px;
}

.b-news-list li {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #d6d8e6;
    margin-bottom: 24px;
    padding: 8px 24px 0 24px;
    overflow: hidden;
    zoom: 1;
}

.b-news-list li.last {
    margin-bottom: 20px;
}

.b-news-list li.fullwidth {
    padding-left: 0;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

.b-news-item {
    overflow: hidden;
    zoom: 1;
    position: relative;
}

.b-news-item.tile, .b-news-list.list-media .b-news-item {
    margin-bottom: 30px;
}

.b-news-image {
    position: relative;
    margin-bottom: 15px;
}

.b-news-image .play {
    display: block;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    width: 80px;
    height: 80px;
    background: url(/images/icon-play.png) no-repeat top left;
}

.b-news-image.floatleft {
    float: left;
    margin: 0 20px 0 0;
}

.b-news-image.floatleft img {
    max-width: 112px;
}

.b-news-image.float {
    margin: 0 24px 0 0;
    float: left;
}

.b-news-list.last .b-news-image.float {
    display: none;
}

.b-news-item.tile .b-news-image, .b-news-top.hero .b-news-image {
    margin-bottom: 0;
}

.b-news-image img {
    max-width: 100%;
    display: block;
}

.b-news-item.tile .b-news-image:after, .b-news-top.hero .b-news-image:after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
    margin-top: -170px;
    height: 170px;
    width: 100%;
    content: '';
}

.b-news-title {
    margin-bottom: 10px;
}

.b-top-sidebar .b-news-title, .imagefull .b-news-title {
    margin-bottom: 0;
}

.b-news-title-author {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 1.6em;
    color: #1c1c1b;
    line-height: 1.3em;
    margin-bottom: 5px;
}

.b-news-title-author.margin {
    margin-bottom: 15px;
}

.b-news-title-author-info {
    font-size: 1.3em;
    color: #1c1c1b;
    line-height: 1.3em;
    margin-bottom: 15px;
}

.b-news-title-author a, .b-news-title-author-info a {
    color: #1c1c1b;
    text-decoration: none;
}

.b-news-list.small .b-news-title {
    margin-bottom: 24px;
}

.b-news-item.tile .b-news-info, .b-news-top.hero .b-news-info, .b-news-imagefull .b-news-info, .imagefull .b-news-info {
    position: absolute;
    bottom: 25px;
    left: 24px;
    margin-bottom: 0;
    padding-right: 15px;
    z-index: 0;
}

.b-top-sidebar .b-news-info {
    bottom: 15px !important;
    left: 15px !important;
}

.b-news-title h2, .b-news-title h1 {
    font-weight: normal;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #000;
    line-height: 1.5em;
    display: inline;
}

.b-news-item.small .b-news-title h2 {
    font-size: 14px;
}

.b-news-title h1 {
    color: #fff;
}

.map-info-window.b-news-item.tile .b-news-title h2 {
    font-size: 16px !important;
}

.gm-style img {
    max-width: 100% !important;
}

.b-news-title h2 a {
    text-decoration: none;
    color: #000;
}

.b-news-title h2 a.selected {
    font-weight: bold;
}

.b-news-title h2 a.selected2 {
    font-weight: bold;
    color: #fd3944;
}

.b-news-title h2 a.selected3 {
    padding-left: 22px;
    background: url(/images/news_ico.png) no-repeat 0 3px;
}

.b-news-item.tile .b-news-title h2 a, .b-news-top.hero .b-news-title h2 a, .b-news-imagefull h1 a {
    color: #fff;
}

.b-news-process {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 14px;
    color: #878787;
}

.b-news-list.small .b-news-process {
    margin: 6px 0 0 4px;
}

.b-news-list.top .b-news-process {
    margin: 6px 0 0 4px;
}

.b-news-process span {
    display: inline-block;
    color: #898989;
    padding: 2px 6px;
    margin: 0 6px 4px 0;
    webkit-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
    -o-transform: skew(-10deg);
    transform: skew(-10deg);
}

.b-news-process span.level1 {
    background: #dfe1e3;
}

.b-news-process span ins {
    display: inline-block;
    text-decoration: none;
    webkit-transform: skew(10deg);
    -moz-transform: skew(10deg);
    -o-transform: skew(10deg);
    transform: skew(10deg);
}

.b-news-process a {
    color: #898989;
    text-decoration: underline;
    display: inline-block;
}

.b-news-process span.level1 a {
    color: #000000;
    text-decoration: none;
}

.b-news-desc {
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    position: relative;
    margin-bottom: 15px;
}

.b-news-desc a {
    color: #1c1c1b;
    text-decoration: none;
}

.b-news-date {
    font-size: 1.2em;
    color: #848696;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
}

.b-news-list.small .b-news-date {
    margin: 10px 0 15px 0;
}

.b-news-top.hero .b-news-date {
    margin: 10px 0 0 0;
}

.b-news-date.full {
    margin-bottom: 30px;
}

.b-news-imagefull {
    position: relative;
}

.b-news-imagefull:after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
    z-index: 0;
}

.b-news-tags {
    margin-bottom: 20px;
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: bold;
    overflow: hidden;
    zoom: 1;
}

.b-news-tags a {
    display: inline-block;
    margin: 0 10px 10px 0;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    padding: 4px 12px;
    border-radius: 12px;
    background-color: #197bff;
}

.b-news-file {
    display: inline-block;
}

.b-news-file.bottom {
    margin-bottom: 30px;
    font-size: 1.4em;
    line-height: 1.3em;
}

.b-news-source {
    display: inline-block;
    padding-left: 20px;
}

.b-news-attention {
    text-align: center;
    margin-bottom: 30px;
}

.b-news-attention-msg h4 {
    font-size: 1.6em;
    line-height: 1.3em;
    color: #000;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 20px;
}

.b-news-attention-msg p {
    font-size: 1.4em;
    font-weight: normal;
    color: #000;
    line-height: 1.3em;
    margin-bottom: 20px;
    padding: 0 30px;
}

.b-news-author-item {
    display: inline-block;
    padding-right: 20px;
}

.b-news-other {
    margin-bottom: 20px;
}

.b-news-person-item, .b-author-item {
    padding: 15px 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-author-item.inlist {
    padding: 0;
    margin-bottom: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.b-blog-item .b-author-item.inlist {
    display: inline-block;
    padding-left: 20px;
    font-weight: normal;
    text-transform: none;
}

.b-blog-item .b-author-item.inlist .b-author-name {
    display: inline;
}

.b-blog-item .b-author-item.inlist .b-author-name a {
    color: #848696;
}

.b-author-img {
    float: left;
    width: 32px;
    margin: -7px 15px 0 0;
}

.b-author-img img {
    max-width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-author-name {
    font-size: 1.2em;
    color: #848696;
    line-height: 1.3em;
    font-weight: bold;
    white-space: nowrap;
    display: inline-table;
}

.b-author-name a {
    color: #1c1c1b;
    text-decoration: none;
}

.b-author-desc {
    font-weight: normal;
    font-size: 1.3em;
    color: #878787;
    line-height: 1.2em;
}

/* documents */
.b-document-list {
    margin-top: 24px;
}

.b-document-item {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #d6d7e2;
}

.b-document-title {
    margin-bottom: 15px;
}

.b-document-title h2 {
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #000;
    line-height: 1.5em;
}

.b-document-title h2 a {
    text-decoration: none;
    color: #000;
}

.b-document-desc {
    font-size: 1.3em;
    color: #000;
    line-height: 1.3em;
}

/* profile */
.b-profile-info {
    overflow: hidden;
    zoom: 1;
    margin-bottom: 30px;
}

.b-profile-ava {
    width: 96px;
}

.b-profile-ava img {
    max-width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-profile-info p {
    padding-bottom: 20px;
}

/* persons */
.b-person-anons {

}

.b-persons .b-search-count {
    display: none;
}

.b-person-list {

}

.b-person-item {
    margin-bottom: 30px;
    text-align: center;
}

.b-person-photo {
    margin: 0 auto 15px auto;
    max-width: 160px;
}

.b-person-photo img {
    max-width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
}

.b-person-item h3 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.6em;
    color: #000;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
}

.b-person-item h3 a {
    color: #1c1c1b;
    text-decoration: none;
}

.b-person-position {
    font-size: 1.4em;
    color: #1f3353;
    line-height: 1.3em;
    margin-bottom: 10px;
}

.b-person-desc {
    font-size: 1.4em;
    color: #7f7f7f;
    line-height: 1.3em;
}

.b-person-desc a {
    color: #7f7f7f;
    text-decoration: none;
}

.b-person-cover {
    position: relative;
}

.b-person-info {
    position: absolute;
    bottom: 30px;
    left: 24px;
    margin-bottom: 0;
    padding-right: 24px;
    z-index: 2;
}

.b-person-info .b-person-position {
    color: #848696;
    margin-bottom: 0;
}

.b-person-name {
    margin-bottom: 20px;
}

.b-person-name h2 {
    font-weight: 400;
    font-family: Roboto Slab, serif;
    font-size: 1.6em;
    color: #fff;
    line-height: 1.5em;
    display: inline;
}

/* process */
.b-specproject {
    margin-bottom: 30px;
}

.b-project-tag {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    color: #fff;
    line-height: 1.3em;
    position: absolute;
    top: 30px;
    left: 24px;
    margin-bottom: 0;
    padding-right: 24px;
    z-index: 2;
}

.b-project-list {

}

.b-project-episodes {
    margin-bottom: 20px;
}

.b-project-episodes a {
    font-size: 1.4em;
    line-height: 1.2em;
    display: inline-block;
}

.b-project-episodes a:hover {
    text-decoration: underline;
}

.b-project .b-search-count {
    display: none;
}

.b-project-item {
    margin-bottom: 30px;
    position: relative;
}

.b-project-item:hover {
    cursor: pointer;
}

.b-project-anons .b-project-cover {
    max-height: 192px;
    overflow: hidden;
}

.b-project-cover {

}

.b-project-cover:after {
    display: block;
    background-image: linear-gradient(180deg, transparent, #000);
    margin-top: -120px;
    height: 120px;
    width: 100%;
    content: "";
    position: absolute;
    bottom: 0;
}

.b-project-cover img {
    max-width: 100%;
    display: block;
}

.b-project-item h3 {
    font-family: 'Roboto Slab', serif;
    font-weight: normal;
    font-size: 1.6em;
    color: #fff;
    line-height: 1.5em;
    position: absolute;
    bottom: 30px;
    left: 24px;
    margin-bottom: 0;
    padding-right: 24px;
    z-index: 2;
}

.b-project-item h3 a {
    color: #fff;
    text-decoration: none;
}

/* contacts */
.b-mapa {

}

.b-page-map {
    position: relative;
}

.map-info-window {
    max-width: 300px;
}

.b-mapa.margin {

}

#page-map {
    height: 600px;
}

#mapa {
    height: 400px;
    margin-bottom: 30px;
}

.b-contacts-form {

}

.b-contacts-form p {
    font-size: 2em;
    line-height: 1.1em;
    color: #35363e;
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    letter-spacing: 6px;
}

.h-contacts-form {
    max-width: 500px;
    margin: 0 auto;
}

.b-contact-text {
    text-align: center;
}

.b-contact-text a {

}

.b-contact-text p {
    font-size: 2em;
    color: #1f3353;
    line-height: 1.4em;
    margin-bottom: 40px;
    font-weight: 300;
}

/* feedback */
.b-form.feedback {

}

.h-feedback-form {
    width: 304px
}

.b-form.feedback.fancybox-content {
    padding: 30px !important;
}

.b-form.feedback .input, .b-form.feedback select, .b-form.feedback textarea {
    max-width: 100%;
}

.b-form.feedback .b-form-row .label {
    display: none;
}

/* search */
.b-search-form {
    margin-bottom: 30px;
    position: relative;
}

.b-search-form .input {
    background: none;
    border: 1px solid #d6d7e2;
    border-width: 0 0 1px 0;
    color: #000000;
    padding: 6px 6px 6px 40px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-search-submit {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 3px;
}

.b-search-form .srcbutton {
    width: 24px;
    text-indent: -9999px;
    height: 24px;
    cursor: pointer;
    display: block;
    background: url(/images/src_btn.png) no-repeat 0 0;
}

.b-search-count {
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e8e8eb url(/images/form_ico.png) no-repeat 20px -220px;
    margin-bottom: 30px;
}

.b-search-error, .b-search-hint {
    font-size: 1.4em;
    color: #c67171;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #f1d7d7 url(/images/form_ico.png) no-repeat 20px 15px;
    margin-bottom: 30px;
}

/* pager */
.b-page-selector {
    position: relative;
    zoom: 1;
    overflow: hidden;
    margin-bottom: 30px;
}

.b-page-selector a, .b-page-selector span.current {
    font-size: 1.2em;
    line-height: 1.2em;
    color: #383838;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #dbdcdd;
}

.b-page-selector span {
    display: inline-block;
}

.b-page-selector span.current {
    text-decoration: none;
    color: #fff;
    background: #000;
    border: 1px solid #dbdcdd;
}

.b-page-selector a:hover {
    text-decoration: none;
    color: #fff;
    background: #000;
}

.b-page-selector .last, .b-page-selector .first {
    display: none;
}

.b-page-selector .next, .b-page-selector .previous {
    text-indent: -9999px;
    vertical-align: 3px;
}

.b-page-selector .next a {
    width: 20px;
    height: 20px;
    padding: 5px;
    background: url(/images/page_nav.png) no-repeat -30px 8px;
    display: block;
    -webkit-border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    -moz-border-radius-bottomright: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.b-page-selector .previous a {
    width: 20px;
    height: 20px;
    padding: 5px;
    background: url(/images/page_nav.png) no-repeat 9px 8px;
    display: block;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

/* form */
.b-form-holder {
    width: 300px;
    margin-bottom: 30px;
    padding: 30px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
}

.b-form-holder .button {
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-form-holder p {
    padding: 20px 0 0 0;
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
}

.b-form-name {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.4em;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 30px;
}

.b-form {

}

.b-form p {
    font-size: 1.4em;
    color: #010f11;
    line-height: 1.3em;
    padding-bottom: 15px;
}

.b-form-row {
    margin-bottom: 20px;
}

.b-form-row p.hint {
    display: none;
}

.b-form-row label {
    font-size: 1.4em;
    color: #010f11;
    line-height: 1.3em;
    padding: 0 14px 10px 0;
    display: table-cell;
    font-family: 'Roboto Slab', serif;
}

.b-form-row label[for=remember_me] {
    display: inline-block;
}

.b-form-row label.required {
    background: url(/images/asterisk.gif) no-repeat top right;
}

.b-form .input, .b-form select, .b-form-row select, .b-form textarea, .b-form-row textarea, .b-form-row input[type=text], .b-form-row input[type=email] {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    width: 100%;
    margin: 0;
    background: #ffffff;
    border-bottom: 1px solid #d6d7e2;
    padding: 8px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#fos_user_profile_form_dateOfBirth select {
    width: 70px;
    margin-right: 10px;
}

.b-form textarea {
    width: 100%;
}

.b-form-row .form-help-label {
    font-size: 1.3em;
    color: #9d9d9d;
    padding-top: 5px;
}

.b-form-row .errors li {
    font-size: 1.3em;
    color: #e39494;
    padding-bottom: 5px;
}

.b-form-submit {

}

.b-form-done, .flash-success {
    font-size: 1.4em;
    color: #83c671;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e2f1d7 url(/images/form_ico.png) no-repeat 20px -103px;
    margin-bottom: 30px;
}

.b-form-notice {
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e8e8eb url(/images/form_ico.png) no-repeat 20px -220px;
    margin-bottom: 30px;
}

.b-form-error {
    font-size: 1.4em;
    color: #c67171;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #f1d7d7 url(/images/form_ico.png) no-repeat 20px 15px;
    margin-bottom: 30px;
}

.form-help-label {
    display: none;
}

/* back-top */
#back-top {
    position: fixed;
    bottom: 90px;
    right: 20px;
    z-index: 222;
    width: 48px;
    height: 48px;
}

#back-top a {
    display: block;
    width: 48px;
    height: 48px;
    background: url(/images/up_btn.png) no-repeat 0 0;
}

#back-top a:hover {

}

.feedback-ico {
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 3333;
    width: 48px;
    height: 48px;
}

.feedback-ico a {
    display: block;
    width: 48px;
    height: 48px;
    background: url(/images/feedback_ico.png) no-repeat 0 0;
}

/* footer */
.b-footer {
    background: #000;
}

.h-footer {
    max-width: 1120px;
    position: relative;
    margin: 0 auto;
    padding: 25px 20px;
}

.b-footer-logo {
    float: left;
    margin: 0 80px 20px 0;
}

body.fatal .b-footer-logo {
    float: none;
}

.b-footer-logo img {
    max-width: 125px;
}

.b-footer-menu {
    margin-bottom: 20px;
}

.h-footer-menu {
    padding-top: 25px;
}

.b-footer-menu li {
    display: inline-block;
    margin-right: 20px;
}

.b-footer-menu li a {
    color: #fff;
    font-size: 1.4em;
    line-height: 1.3em;
    text-decoration: none;
    display: inline-block;
}

.b-footer-social {
    float: none;
    margin: 0;
    position: absolute;
    right: 20px;
    top: 45px;
    z-index: 1;
}

.b-footer-social a.facebook {
    background: url(/images/social.png) no-repeat 0 -22px;
}

.b-footer-social a.telegram {
    background: url(/images/social.png) no-repeat -22px -22px;
}

.b-copyright {
    font-size: 1.3em;
    line-height: 1.3em;
    color: #fff;
    font-weight: normal;
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 920px) {
    .b-content.sidebar {
        float: none;
        margin-right: 0;
    }

    .b-content.sidebar .b-content-block {
        margin-right: 0;
    }

    .b-sidebar-block {
        float: none;
        width: 100%;
    }

    .b-sidebar-block.main {
        padding-top: 0;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 820px) {
    .b-search-header-form .input {
        width: 90px;
    }

    .b-site-nav {
        display: none;
        position: absolute;
        left: -20px;
        top: -20px;
        z-index: 222;
        width: 180px;
        padding: 25px;
        background: #fff;
        -webkit-border-top-right-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -moz-border-radius-topright: 6px;
        -moz-border-radius-bottomright: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
    }

    .b-site-nav li {
        display: block;
        float: none;
        margin: 0 0 10px 0;
        text-align: center;
    }

    .b-site-nav li a {
        padding-bottom: 5px;
    }

    .b-menu-ico, .b-site-nav-ico {
        display: block;
    }

    .b-search-header {
        bottom: 0;
    }

    .b-lang {
        bottom: auto;
        top: 0;
        right: 0;
    }
}

@media only screen and (max-width: 680px) {
    .b-site-nav {
        display: none;
        position: fixed;
        height: 100vh;
        left: 0;
        right: 0;
        top: 0;
        z-index: 222;
        width: 100%;
        padding: 60px 0;
        border-radius: 0;
        background: #fff url(/images/back.png);
    }

    .b-user-auth {
        top: auto;
        bottom: 0;
    }

    .b-header {
        padding: 10px 20px;
    }

    .b-block-wrap {
        padding: 0 20px;
    }

    .b-static-text table {
        display: block;
        overflow-x: auto;
    }

    .h-footer {
        padding: 20px;
    }

    .b-footer-logo {
        margin-right: 20px;
    }

    .b-footer-social {
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 20px;
        text-align: center;
    }

    .b-top-holder {
        margin-bottom: 0;
    }

    .b-blog-image {
        float: none;
        margin: 0 0 15px 0;
        max-width: 100%;
    }

    .b-blog-image img, .b-news-image img {
        width: 100%;
    }

    .b-sidebar-links {
        overflow-x: hidden;
    }

    .b-sidebar-links ul {
        display: flex;
        overflow-x: auto;
        justify-content: start;
    }

    .b-sidebar-links li {
        margin-left: 15px;
        min-width: 300px;
    }

    .b-sidebar-links li:first-child {
        margin-left: 0;
    }

    .b-news-imagefull .b-news-info, .b-news-item.tile .b-news-info, .b-news-top.hero .b-news-info, .b-person-info, .imagefull .b-news-info {
        bottom: 15px;
        left: 15px;
    }

    .b-project-item h3, .b-slider-text {
        bottom: 15px;
    }

    .b-news-title, .b-person-name {
        margin-bottom: 10px;
    }

    .b-news-list.small .b-news-title {
        margin-bottom: 0;
    }

    .b-news-list li {
        padding: 10px 8px 8px 8px;
    }

    .b-news-list.small .b-news-date {
        margin: 0 0 6px 0;
    }

    .b-news-title h2, .b-news-title h1 {
        font-size: 1.4em;
        line-height: 1.4em;
    }

    .b-news-imagefull:after {
        margin-top: -90px;
        height: 90px;
    }

    .b-news-date {
        font-size: 10px;
    }

    .b-form-holder {
        margin: 100px auto 30px auto;
    }

    .b-footer-logo {
        display: none;
    }

    .b-copyright {
        text-align: center;
    }

    .h-footer-menu {
        padding-top: 0;
    }

    .b-footer-menu li {
        margin: 0;
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
}

/*TODO: DELETE*/
.voting {
    display: flex;
    font-size: 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: left;
}

.voting .voting__item {
    border: 1px solid #0d6aad;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    flex-grow: 1;
    width: 200px;
    max-width: 200px;
}

.voting .voting__title {
    border-bottom: 1px solid #0d6aad;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 10px
}

.voting .voting__item .voting__options {
    width: 100%;
}

.voting .voting__item .voting__options .option__item {
    cursor: pointer;
    border: 1px solid #0d6aad;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    transition: color .25s, background-color .25s;
    white-space: normal;
}

.voting .voting__item .voting__options .option__item.active {
    border: 1px solid #ff0000;
}

.voting .voting__item .voting__options .option__item:hover {
    background: #0d6aad;
    color: #fff;
}

/*END TODO*/

.chart a:hover {
    color: #fff;
}

.profile-subscription {
    padding-top: 5px;
}

.profile-subscription-info {
    font-size: 16px;
    line-height: normal;
}

.profile-subscription .subscription_expiration_date {
    font-weight: bold;
}

.subscribe_button {
    margin-top: 5px !important;
}
/*-----------------*/

.chart-top-ul li{
    display: flex;
    flex-direction: column
}
.chart-top-ul li img{
    width: 100%;
}
@media(max-width: 920px){
    .chart-top-ul{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .chart-top-ul li{
        width: 45%;
    }

}

.chart-popup{
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    display: flex;
    align-items: center;
}
.chart-popup.show{
    opacity: 1;
    visibility: visible;
}
.chart-popup__close {
    position: absolute;
    right: 40px;
    top: 20px;
    padding: 20px;
    cursor: pointer;
    z-index: 2;
}
.chart-popup__background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: -1;
}
.chart-popup__content{
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.chart-popup__content .img-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40px;
    left: 100px;
    right: 100px;
    bottom: 40px;
    overflow: hidden;
}

.chart-popup__content .img-wrapper img{
    height: 100%;
    cursor: zoom-out;
}

@media(max-width: 1024px){
    .chart-popup__content .img-wrapper{
        left: 40px;
        right: 40px;
        /*top: ;*/
    }
    .chart-popup__content .img-wrapper img{
        height: auto;
        width: 100%;
    }
}

.iframeWrapper{
    position: relative !important;
    padding-bottom: 56.25% !important; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0 !important;
    overflow: hidden !important;
}
.iframeWrapper iframe{
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border-width: 0 !important;
    outline-width: 0 !important;
}


/*-----blog----*/
.blog-container{
    max-width: 1120px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 1fr 270px;
    grid-gap: 25px;
    padding-bottom: 50px;
    position: relative;
    box-sizing: border-box;
}

.blog-container a{
    text-decoration: none !important;
}
.blog-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
}

.columns-2{
    grid-column-start: 1;
    grid-column-end: 3;
    min-height: 210px !important;

}
.columns-2 .blog-content-item{
    min-height: 210px !important;
}

.blog-content-item{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 25px;
    min-height: 345px;
}
.blog-content-item:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    z-index: 0;
    background: linear-gradient(180deg, rgba(152, 152, 152, 0.0001) 0%, rgba(0, 0, 0, 0.700776) 44.72%);
 }

.blog-content-item__text{
    z-index: 1;
}
.blog-content-item__title{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.blog-content-item__description{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: #848696;
}


.blog-authors .link-more{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: flex-end;
    text-decoration-line: underline;
    color: #000000;
}



.blog-authors-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    margin-bottom: 25px;
}
.blog-authors-item{
    display: grid;
    grid-template-rows: 145px auto;
    background: #ffffff;
}
.blog-authors-item__img{
    width: 100%;
    overflow: hidden;
}
.blog-authors-item__img img{
    width: 100%;
}
.blog-authors-item__content{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 10px;
}
.blog-authors-item__content .text{
    display: flex;
    flex-direction: column;
}
.blog-authors-item__content .text .name{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
}
.blog-authors-item__content .text .job {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: #848696;
}
.blog-authors-item__content .stars{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #848696;
    padding-right: 10px;
}
.blog-authors-item__content .stars svg{
    margin-right: 10px;
}

.blog-authors-mob{
    position: absolute;
    right: 25px;
    display: none;
    z-index: 10;
}
.blog-authors-mob-btn{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration-line: underline;
    color: #000000;
    padding-bottom: 10px;
    cursor: pointer;
    user-select: none;
    width: max-content;
    margin-left: auto;
}
.blog-authors-mob-btn svg{
    margin-left: 8px;
    transform: rotate(0deg);
    transition: .3s;
}
.blog-authors-mob-select{
    display: flex;
    flex-direction: column;
    list-style: none;
    background: #FFFFFF;
    box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.145924);
    border-radius: 0px 0px 6px 6px;
}
.blog-authors-mob-select li{
    min-height: 50px;
    min-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1px solid #D6D7E2;
}
.blog-authors-mob-select li a{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    text-align: center;
    width: 100%;
}

.blog-authors-mob.active .blog-authors-mob-btn svg{
        transform: rotate(180deg);
}


@media (max-width: 1260px) {
    .blog-container{
        max-width: 992px;
    }
}
@media (max-width: 820px) {
    .blog-container{
        max-width: unset;
        padding-left: 25px;
        padding-right: 25px;
        grid-template-columns: 1fr;
    }

    .blog-content-item{
        min-height: 225px;
    }

    .blog-content-item__title{
        font-size: 14px;
        line-height: 24px;
    }
    .blog-content-item__description{
        font-size: 12px;
        line-height: 24px;
    }




    .blog-authors{
        display: none;
    }
    .blog-authors-mob{
        display: block;
    }
    .blog-authors-mob-select{
        display: none;
    }

}
@media(max-width: 600px) {

    .blog-container {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;
    }
    .columns-2{
        grid-column-start: unset;
        grid-column-end: unset;
    }
    .blog-content-wrapper{
        grid-template-columns: 1fr;
    }


    .blog-authors-mob{
        right: 15px;
        left: 15px;
    }
    .blog-authors-mob-btn{
        /*pb40*/
    }
}

/*----authors*/
#author {
    margin-left: auto;
    margin-right: auto;
    max-width: 856px;
}

.author-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;

    max-width: 865px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    position: relative;
}
.author-container a, .author-container span{
    text-decoration: none !important;
}

@media (max-width: 820px) {
    .author-container {
        max-width: unset;
        padding-left: 25px;
        padding-right: 25px;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .author-container {
        padding-left: 0px;
        padding-right: 0px;
        grid-template-columns: 1fr;
    }
    .blog-authors-item {
        grid-template-rows: 185px auto;
    }
    .blog-authors-item__content{
        padding: 10px 30px;
    }
    .blog-authors-item__content .stars{
        padding-right: 0;
    }
}

/*---stream----*/
.stream-container{
    max-width: 885px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    position: relative;
}
.stream-wrapper a{
    text-decoration: none !important;
}
.stream-watch-stream{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline !important;
    color: #000000;

    position: absolute;
    right: 20px;
    top: 7px;
    z-index: 1;
}
.stream-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
}

.stream-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
}

.stream-simple-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;

    padding: 0 15px;
    width: 100%;
}
.stream-text-border {
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 20px;
    overflow: hidden;
    width: 100%;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;

}
.stream-text-border__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 210px;
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -15px;
}

.stream-text-border__info {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #848696;
    margin-bottom: 20px;
}
.stream-text-border__info span{
    margin-right: 15px;
}
.stream-text-border__info span:last-child{
    margin-right: 0;
}

@media (max-width: 820px) {
    .stream-container{
        max-width: unset;
        padding-left: 25px;
        padding-right: 25px;
    }
    .stream-container {
        grid-gap: 15px;
    }
    .stream-watch-stream {
        right: 25px;
        font-size: 14px;
    }
    .stream-container .blog-content-item{
        min-height: 345px;
    }
}

@media (max-width: 340px) {
    .stream-watch-stream span{
        display: none;
    }
}

.pagination, .pagination * {
    box-sizing: content-box;
}

.blog-container .pagination {
    margin-top: 20px;
}

.radioChecked input, .selectChecked input{
    display: none;
}
.radioChecked div label, .selectChecked label{
    position: relative;
    padding-left: 28px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}
.radioChecked div label::before, .selectChecked label::before{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url(/images/no-checked.png);
    background-size: cover;
}
.radioChecked div input:checked+label::after, .selectChecked input:checked+label::after{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url(/images/checked.png);
    background-size: cover;
}



