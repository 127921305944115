
.poll {
    width: max-content;
    margin-top: 10px;
    margin-bottom: 40px;
    min-width: 296px;
    max-width: 520px;
}

@media screen and (max-width: 375px) {
    .poll {
        min-width: 250px;
        margin: 10px auto 40px auto;
    }
}
.poll__item{
    background: #FFFFFF;
    box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.145924);
    border-radius: 6px;
    overflow: hidden;
    position:relative;
}
.poll-result{
    box-sizing: border-box;
    min-height: 370px;
    background: #fff;
    box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.145924);
}
.poll-result__item{
    padding: 43px 40px 53px 40px;
    box-shadow: none;
}

.poll__item-vote{
    box-sizing: border-box;
    min-height: 370px;
    padding: 43px 40px 53px 40px;
    background: #FFFFFF;

}
.poll__btn {
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.poll__actions {
    margin-top: 48px;
}

.poll__vote {
    padding: 12px 58px;
    border: none;
    background: #0C0C0C;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    border-radius: 0;
}

.poll__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 35px;
}
.poll__headline{
    margin-bottom: 45px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.2;
    color: #848696;
}
.poll__option:hover .poll__option-btn{
    color:#000;
    transition: .5s;
}

.poll__option.checked .poll__option-btn {
    color: #000;
    transition: .5s;
}
.poll__option:not(:last-child) {
     margin-bottom: 16px;
 }
.poll__option-wrapper {
    display: flex;
    align-items: center;
    transition: transform 0.25s;
}

.poll__option-wrapper.selected {
    background: #4e4e4e;
}

.poll__option.active > div {
    color: red;
}
.poll__option-btn {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #848696;
}

.poll-result-percentage{
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: #000000;
    position: relative;
    width: 20px;


}

.poll-result-percentage {
    display: flex;
    flex-shrink: 0;
}
.poll-result-data{
    display: flex;
}


.poll-result-data:not(:last-child){
    margin-bottom: 16px;
}
.poll-result-block{
    transform: translateY(-10px);
    margin-left: 19px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}
.poll-result-line{
    width: 100%;
    height: 2px;
    background: #000000;
    max-width: 250px;
    min-width: 1%;
}

.poll-result__answer {
    max-width: 320px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 3px;
    color: #848696;
}
.poll-result-line.active{
    background: #357DEE;
}

.poll-result__answer.active {
    color: #000000;

}
.switch {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    -webkit-user-select: none;
    position: absolute;
    cursor: pointer;
    transform: translateY(-55%);
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
    width: 16px;
    height: 16px;
    border: 1px solid #848696;
}
input:checked + .slider {
    background: #357DEE;
    border: 1px solid #357DEE;

}
input:checked + .slider:before {
    position: absolute;
    left: 50%;
    content: "\2714";
    transform: translate(-5px ,2px);
    font-size: 13px;

    color: #fff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #000;
}
